const serviceName = 'timee-app';
const version = 'v1.0';

export const localStorageKeys = {
  flashMessages: {
    message: `${serviceName}/${version}/flashMessages`,
    reloadFlag: `${serviceName}/${version}/flashMessages/reload`,
  },
  development: {
    featureFlags: `${serviceName}/${version}/featureFlags`,
  },
  minifySidebar: `${serviceName}/${version}/minifySidebar`,
  admin: {
    redirectionPath: `${serviceName}/${version}/admin/redirectionPath`,
  },
} as const;
