export const swrKeys = {
  fetchNoticeList: (page: number, categoryIds: number[]) =>
    `fetchNoticeList/${page}/${categoryIds.join(',')}`,
  fetchVersionKey: `fetchVersionKey`,
  fetchBusinessFactCheckList: `fetchBusinessFactCheckList`,
  fetchBusinessFactCheckDetail: (businessFactCheckId: number) => `
    fetchBusinessFactCheckDetail/${businessFactCheckId}`,
  fetchBusinessFactCheckJudge: (businessFactCheckId: number) => `
    fetchBusinessFactCheckJudge/${businessFactCheckId}`,
  fetchAdminUserRole: `fetchAdminUserRole`,
} as const;
